
const prodUrl = 'http://34.163.66.150:9090/v1/dev';
const testUrl = 'http://localhost:9090/v1/test';
const devUrl = 'http://localhost:9090/v1/dev';

const server = prodUrl;

const apis = {
    authService: server + '/user',
    userService: server + '/user',
    postService: server + '/post',
    likeService: server + '/like'
}

export default apis;
